import { action, computed, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';

import { apiFetcher } from '../utils/fetch';
import { updateItems } from './utils';

export default class Tenant {
  constructor() {
    this.selected = {};
    this.filters = { searchText: '', status: ['inprogress','end_soon','booked'], propertyId: "" };
    this.items = [];

    makeObservable(this, {
      selected: observable,
      filters: observable,
      items: observable,
      filteredItems: computed,
      setSelected: action,
      setFilters: action,
      fetch: flow,
      fetchOne: flow,
      create: flow,
      update: flow,
      delete: flow,
      deleteAll: flow,
      deleteOccwithRent: flow,
      deleteAll: flow,
      sendEmail: flow,
      sendMessage: flow,
      sendPaymentByEmail: flow,
      sendPaymentByWhatsapp: flow,
    });
  }

  get filteredItems() {
    let filteredItems =
      this.filters.status?.length === 0
        ? this.items
        : this.items.filter(({ status }) =>
          this.filters.status.includes(status)
        );

    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText
        .toLowerCase()
        .replace(regExp, '');

      filteredItems = filteredItems.filter(
        ({ isCompany, name, manager, contacts, properties }) => {
          // Search match name
          let found =
            name.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !=
            -1;

          // Search match manager
          if (!found && isCompany) {
            found =
              manager
                ?.replace(regExp, '')
                .toLowerCase()
                .indexOf(cleanedSearchText) != -1;
          }

          // Search match contact
          if (!found) {
            found = !!contacts
              ?.map(({ contact = '', email = '', phone = '' }) => ({
                contact: contact.replace(regExp, '').toLowerCase(),
                email: email.toLowerCase(),
                phone: phone.replace(regExp, ''),
              }))
              .filter(
                ({ contact, email, phone }) =>
                  contact.indexOf(cleanedSearchText) != -1 ||
                  email.indexOf(cleanedSearchText) != -1 ||
                  phone.indexOf(cleanedSearchText) != -1
              ).length;
          }

          // Search match property name
          // if (!found) {
          //   found = !!properties?.filter(
          //     ({ property: { name } }) =>
          //       name
          //         .replace(regExp, '')
          //         .toLowerCase()
          //         .indexOf(cleanedSearchText) != -1
          //   ).length;
          // }
          return found;
        }
      );
    }

    if (this.filters.propertyId) {
      filteredItems = filteredItems.filter(
        ({ property }) => this.filters.propertyId.includes(property?.propertyId)
      )
    }
    return filteredItems;
  }

  setSelected = (tenant) => (this.selected = tenant);

  setFilters = ({ searchText = '', status = [], propertyId = "" }) =>
    (this.filters = { searchText, status, propertyId });

  *fetch(query) {
    try {
      let baseURL = `/tenants`;
      let queryString;
      if (query) {
        queryString = Object.keys(query)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
          .join('&');
      }
      const fullURL = `${baseURL}?${queryString}`;
      const response = yield apiFetcher().get(fullURL);

      this.items = response.data;
      if (this.selected._id) {
        this.setSelected(
          this.items.find((item) => item._id === this.selected._id) || {}
        );
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchOne(tenantId) {
    try {
      const response = yield apiFetcher().get(`/tenants/${tenantId}`);
      const updatedTenant = response.data;
      this.items = updateItems(updatedTenant, this.items);
      if (this.selected?._id === updatedTenant._id) {
        this.setSelected(updatedTenant);
      }
      return { status: 200, data: updatedTenant };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(tenant) {
    try {
      const response = yield apiFetcher().post("/tenants", tenant);
      const createdTenant = response.data;
      this.items = updateItems(createdTenant, this.items);

      return { status: 200, data: createdTenant };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(tenant) {
    try {
      const response = yield apiFetcher().patch(`/tenants/${tenant._id}`, tenant);
      const updatedTenant = response.data;
      this.items = updateItems(updatedTenant, this.items);
      if (this.selected?._id === updatedTenant._id) {
        this.setSelected(updatedTenant);
      }
      return { status: 200, data: updatedTenant };
    } catch (error) {
      const status = error?.response?.status
      const errors = error?.response?.data?.errors || []
      return { status, errors };
    }
  }

  *delete(ids) {
    try {
      yield apiFetcher().delete(`/tenants/${ids.join(",")}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deleteOccwithRent(id) {
    try {
      const response = yield apiFetcher().delete(`/tenants/rent/${id}`);
      const successMessage = response?.data || "Penyewa berhasil dihapus";
      return { status: 200, message: successMessage };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deleteAll() {
    try {
      const response = yield apiFetcher().delete(`/tenants`);
      const successMessage =
        response?.data || "Semua data penyewa berhasil dihapus";
      return { status: 200, message: successMessage };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deleteAll() {
    try {
      const response = yield apiFetcher().delete(`/tenants`);
      const successMessage =
        response?.data || "Semua data penyewa berhasil dihapus";
      return { status: 200, message: successMessage };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *sendEmail(id, payload) {
    try {
      const response = yield apiFetcher().post(`/emails/invoice/${id}`, payload);
      return { status: 200, data: response.data };
      // yield apiFetcher().post(`/emails/invoice/${id}`, payload);
      // return 200;
    } catch (error) {
      return error.response.status;
    }
  }

  *sendMessage(id, payload) {
    try {
      // yield apiFetcher().post(`/messages/invoice/${id}`, payload);
      // return 200;
      const response = yield apiFetcher().post(`/messages/invoice/${id}`, payload);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *sendPaymentByEmail(id, payload) {
    try {
      const response = yield apiFetcher().post(
        `/emails/invoice/${id}/paymentreceipt`,
        payload
      );
      return { status: 200, data: response.data };
    } catch (error) {
      return error.response.status;
    }
  }

  *sendPaymentByWhatsapp(id, payload) {
    try {
      const response = yield apiFetcher().post(
        `/messages/invoice/${id}/paymentreceipt`,
        payload
      );
      return { status: 200, data: response.data };
    } catch (error) {
      return error.response.status;
    }
  }
}
